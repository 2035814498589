import { useState, useEffect } from 'react';
import { fetchImage } from '../api';

export default function RacketImage({ imageUuid, racketName, className = "h-full w-full object-cover object-center sm:h-full sm:w-full" }) {
  const [imageUrl, setImageUrl] = useState('/images/logo.png');

  useEffect(() => {
    const loadImage = async () => {
      if (imageUuid) {
        try {
          const url = await fetchImage(imageUuid);
          setImageUrl(url);
        } catch (error) {
          console.error('Error loading image:', error);
        }
      }
    };

    loadImage();
  }, [imageUuid]);

  return (
    <img
      src={imageUrl}
      alt={racketName}
      className={`${className}`}
    />
  );
}
