import React, { useState, useEffect } from 'react';
import { useBreadcrumb } from '../BreadcrumbContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IntlProvider, FormattedNumber } from 'react-intl';
import RacketImage from './RacketImage'

export default function RacketComparator({ rackets }) {
  const { t } = useTranslation();
  const [comparator, setComparator] = useState([]);
  const { setBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    const storedComparator = JSON.parse(localStorage.getItem('racketComparator')) || [];
    setComparator(storedComparator);
  }, []);


  useEffect(() => {
    setBreadcrumbs([
      { name: t('Home'), href: '/' },
      { name: t('Badminton'), href: '/badminton/rackets' },
      { name: t('Racket comparator'), href: '/badminton/racket-comparator' },
    ]);
  }, [setBreadcrumbs, t]);

  const removeRacket = (uuid) => {
    const updatedComparator = comparator.filter(id => id !== uuid);
    setComparator(updatedComparator);
    localStorage.setItem('racketComparator', JSON.stringify(updatedComparator));
  };

  const clearComparator = () => {
    setComparator([]);
    localStorage.removeItem('racketComparator');
  };

  const comparedRackets = rackets.filter(racket => comparator.includes(racket.uuid));

  return (
    <main className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
      <div className="border-b border-gray-200 pb-10 pt-24">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900">{t('Racket Comparator')}</h1>
        <p className="mt-4 text-base text-gray-500">
          {t('Compare your selected rackets in a detailed table to find the best match for your playing style.')}
        </p>
      </div>

      {comparedRackets.length === 0 ? (
        <div className="text-center mt-10">
          <p className="text-lg text-gray-500">{t('No rackets in comparator')}</p>
          <p className="text-base text-gray-500 mt-4">
            {t('You can add rackets to the comparator by navigating through the site and selecting the rackets you want to compare.')}
          </p>
          <Link
            to="/badminton/rackets"
            className="mt-6 inline-block rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
          >
            {t('Browse Rackets')}
          </Link>
        </div>
      ) : (
        <div className="mt-8 flow-root">
          <div className="mb-6 text-right">
            <button
              type="button"
              onClick={clearComparator}
              className="inline-block rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              {t('Clear Comparator')}
            </button>
          </div>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('Characteristic')}
                    </th>
                    {comparedRackets.map(racket => (
                      <th key={racket.uuid} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <div className="flex justify-center items-center">
                          {racket.name}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Look')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                          <RacketImage imageUuid={racket.images && racket.images[0]} racketName={racket.name} className="h-32 w-24 mx-auto rounded-full"/>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Brand')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        {racket.brand || t('Information not available')}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Player type')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        {t(`${racket.player_type}_SHORT`) || t('Information not available')}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Balance')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        {t(racket.balance) || t('Information not available')}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Flexibility')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        {t(racket.flexibility) || t('Information not available')}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Weights')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        <div dangerouslySetInnerHTML={{ __html: racket.weights.length > 0 ? racket.weights.join('<br>') : t('Information not available') }} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Grip sizes')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        <div dangerouslySetInnerHTML={{ __html: racket.grip_sizes.length > 0 ? racket.grip_sizes.join('<br>') : t('Information not available') }} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Max tensions')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        {racket.max_tensions.length > 0 ? (
                          <div>
                            {racket.max_tensions.map((tension, index) => (
                              <div key={index}>
                                {tension} kg / {(tension * 2.20462).toFixed(2)} lbs
                              </div>
                            ))}
                          </div>
                        ) : (
                          t('Information not available')
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Materials')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        <div dangerouslySetInnerHTML={{ __html: racket.materials.length > 0 ? racket.materials.join('<br>') : t('Information not available') }} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Technologies')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                        <div dangerouslySetInnerHTML={{ __html: racket.technologies.length > 0 ? racket.technologies.join('<br>') : t('Information not available') }} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Price')}
                    </td>
                    {comparedRackets.map(racket => {
                      return (
                        <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-center">
                          {racket.averagePrice ? (
                          <IntlProvider locale="fr">
                            <FormattedNumber value={racket.averagePrice.amount} style="currency" currency={racket.averagePrice.currency} />
                          </IntlProvider>
                          ) : (
                            t('Price not available')
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-900 font-medium">
                      {t('Actions')}
                    </td>
                    {comparedRackets.map(racket => (
                      <td key={racket.uuid} className="whitespace-nowrap px-3 py-5 text-center">
                        <div className="flex flex-col items-center justify-center">
                          <Link
                            to={`/badminton/racket/${racket.uuid}`}
                            className="mb-2 text-sm text-blue-600 hover:text-blue-900"
                          >
                            {t('View Details')}
                          </Link>
                          <button
                            onClick={() => removeRacket(racket.uuid)}
                            className="text-sm text-red-600 hover:text-red-900"
                          >
                            {t('Remove')}
                          </button>
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
