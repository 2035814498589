//const API_BASE_URL = "http://localhost:3001/api";
const API_BASE_URL = "https://rackets.top/api";

export const addPrice = async ({ racketUuid, price, currency, link, turnstileToken, onPriceSubmit, onClose, setTurnstileToken, t }) => {
  try {
    const response = await fetch(`${API_BASE_URL}/rackets/add-price`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        racketUuid,
        price: parseFloat(price),
        currency,
        link,
        turnstileToken,
      }),
    });

    if (response.ok) {
      onPriceSubmit();
      setTurnstileToken("");
      onClose();
    } else {
      alert(t('There was an error submitting your price. Please try again later.'));
    }
  } catch (error) {
    console.error('Error submitting price:', error);
    alert(t('There was an error submitting your price. Please try again later.'));
  }
};

export const reportError = async ({ racketUuid, errorMessage, turnstileToken, onReportSuccess, setTurnstileToken, t }) => {
  try {
    const response = await fetch(`${API_BASE_URL}/rackets/report-error`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        racketUuid,
        message: errorMessage,
        turnstileToken,
      }),
    });

    if (response.ok) {
      onReportSuccess();
      setTurnstileToken("");
    } else {
      alert(t('There was an error submitting your report. Please try again later.'));
    }
  } catch (error) {
    console.error('Error submitting report:', error);
    alert(t('There was an error submitting your report. Please try again later.'));
  }
};

export const fetchRackets = async () => {
  try {
    // 1. Récupérer les données rackets et exchange-rates
    const response = await fetch(`${API_BASE_URL}/rackets`);
    const exchangeRatesResponse = await fetch(`${API_BASE_URL}/exchange-rates`);

    const exchangeRatesData = await exchangeRatesResponse.json();
    const data = await response.json();

    // 2. Convertir tous les prix en EUR
    const racketsWithPricesInEUR = data.map((racket) => {
      // Convertit chaque prix en EUR et ajoute la propriété amountInEUR
      racket.prices = racket.prices.map((price) => {
        const exchangeRate = exchangeRatesData.rates[price.currency];
        const priceInEUR = (price.amount / exchangeRate).toFixed(2);
        return {
          ...price,
          amountInEUR: parseFloat(priceInEUR),
        };
      });

      // 3. Calculer la moyenne en EUR
      if (racket.prices.length > 0) {
        const sumInEUR = racket.prices.reduce(
          (acc, price) => acc + price.amountInEUR,
          0
        );
        const averageInEUR = sumInEUR / racket.prices.length;

        racket.averagePrice = {
          amount: parseFloat(averageInEUR.toFixed(2)), // formate à 2 décimales
          currency: 'EUR',
        };
      } else {
        racket.averagePrice = null;
      }

      return racket;
    });

    return racketsWithPricesInEUR.sort(() => Math.random() - 0.5);
  } catch (error) {
    console.error('Error fetching rackets:', error);
    return [];
  }
};


export const fetchImage = async (uuid) => {
  try {
    const response = await fetch(`${API_BASE_URL}/images/${uuid}`);

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error fetching image:", error);
    return "/images/logo.png"; // Retourne une image par défaut en cas d'erreur
  }
};